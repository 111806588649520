<template>
  <div class="video-container">
    <video class="payment-video" autoplay loop muted playsinline>
      <source src="@/assets/waitingPayment.webm" type="video/webm" />
      Tu navegador no soporta el formato de video `.webm`.
    </video>
    <h2>Procesando Pago</h2>
    <p>Por favor acerque su tarjeta a la terminal de pagos</p>
    <p class="confirmation-status">Esperando confirmación...</p>
    <div class="order-total">
      <span>Total de la Orden:</span>
      <span class="value">$ {{ total }}</span>
    </div>
  </div>
</template>

<script>
import { getIfIsValidPaymentPointPlus } from "@/plugins/axios";

export default {
  name: "PaymentProcessing",
  data() {
    return {
      total: null,
      externalReference: null,
      pollingInterval: null,
    };
  },
  mounted() {
    this.total = this.$route.query.total;
    this.externalReference = this.$route.query.externalReference;
    // Iniciar polling cuando el componente se crea
    this.startPolling();
  },
  methods: {
    startPolling() {
      this.pollingInterval = setInterval(async () => {
        try {
          getIfIsValidPaymentPointPlus(this.externalReference)
            .then((res) => {
              if (res?.data?.responseCode === 0) {
                const status = res.data.status;
                if (status == 1) {
                  clearInterval(this.pollingInterval); // Detiene el polling
                  this.$router.push({
                    //Redirijo a la pagina de pago approved
                    name: "MenuWebordersPaymentPointPlusApproved",
                    query: {
                      ...this.$route.query,
                      total: this.total,
                    },
                  });
                } else if (status == 2) {
                  clearInterval(this.pollingInterval); // Detiene el polling
                  this.$router.push({
                    //Redirijo a la pagina de pago cancelado
                    name: "MenuWebordersPaymentPointPlusCanceled",
                    query: {
                      ...this.$route.query,
                      total: this.total,
                    },
                  });
                }
              } else {
                console.error(
                  "El pago no fue procesado correctamente.",
                  res?.data
                );
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } catch (error) {
          console.error("Error fetching transaction status:", error);
        }
      }, 5000); // Cada 5 segundos
      // },
    },
    beforeDestroy() {
      // Se ejecuta antes de destruir el componente
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval); // Limpia el intervalo
      }
    },
  },
};
</script>

<style scoped>
.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
  margin-top: 0;
}

.payment-video {
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

h2 {
  font-size: 1.5em;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #333;
}

p {
  font-size: 1em;
  margin-top: 25px;
  margin-bottom: 5px;
  color: #666;
}

.confirmation-status {
  color: #007bff;
  font-weight: bold;
  margin-top: 0px;
}

.order-total {
  margin-top: 20px;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  font-size: 1.2em;
  font-weight: bold;
}

.value {
  margin-left: 120px;
}
</style>
